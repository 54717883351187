import logo from './logo.svg';
import './App.css';
import MainPage from "./componets/MainPage";
import React, {useEffect} from "react";

function App() {
  useEffect(() => {
    document.title = "MagicVoice";
  }, []);
  return (
    <div className="App">
      <MainPage/>
    </div>
  );
}

export default App;
