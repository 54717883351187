// reducers/textinputReducer.js
import { UPDATE_INPUT_VALUE } from './actions.js'

const initialState = {
    inputValue: '',
  };
  
  const textinputReducer = (state = initialState, action) => {
    switch (action.type) {
      case UPDATE_INPUT_VALUE:
        return {
          ...state,
          inputValue: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default textinputReducer;
  