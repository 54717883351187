export const SET_GENERATED_LINK = 'SET_GENERATED_LINK';
export const UPDATE_INPUT_VALUE = 'UPDATE_INPUT_VALUE';
export const SET_INVALID_INPUT = 'SET_INVALID_INPUT';
export const SET_GENERATE_CLICKED = 'SET_GENERATE_CLICKED';

export const setGeneratedLink = (link) => ({
  type: SET_GENERATED_LINK,
  payload: link,
});

export const updateInputValue = (inputvalue) => ({
  type: UPDATE_INPUT_VALUE,
  payload: inputvalue,
});

export const setInvalidInput = (invalidInput) => ({
  type: SET_INVALID_INPUT,
  payload: invalidInput,
});

export const setGenerateClicked = (generateClicked) => ({
  type: SET_GENERATE_CLICKED,
  payload: generateClicked,
});