import { SET_GENERATE_CLICKED } from './actions';

const initialState = {
    generateClicked: false,
  };
  
  const generateClickedReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_GENERATE_CLICKED:
        return {
          ...state,
          generateClicked: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default generateClickedReducer;