import {TopNavigation} from "@cloudscape-design/components";
import Grid from "@cloudscape-design/components/grid";
import ColumnLayout from "@cloudscape-design/components/column-layout";
import SpaceBetween from "@cloudscape-design/components/space-between";
// import Input from "@cloudscape-design/components/input";
import React, {useState} from "react";
import Textinput from "./Textinput";
import Controlpanel from "./Controlpanel";
// import AudioPlayer from "./Player";
// import Layout from "./Layout";
// import Sidebar from "./Sidebar";

function MainPage() {

    return (<>
        <SpaceBetween size="l">
        <TopNavigation
            identity={{href: "#", title: "MagicVoice"}}
            // utilities={[{
            //     type: "button",
            //     text: "Link",
            //     href: "https://example.com/",
            //     external: true,
            //     externalIconAriaLabel: " (opens in a new tab)"
            // }, {
            //     type: "button",
            //     iconName: "notification",
            //     title: "Notifications",
            //     ariaLabel: "Notifications (unread)",
            //     badge: true,
            //     disableUtilityCollapse: false
            // }, {
            //     type: "menu-dropdown",
            //     iconName: "settings",
            //     ariaLabel: "Settings",
            //     title: "Settings",
            //     items: [{id: "settings-org", text: "Organizational settings"}, {
            //         id: "settings-project",
            //         text: "Project settings"
            //     }]
            // }, {
            //     type: "menu-dropdown",
            //     text: "Customer Name",
            //     description: "email@example.com",
            //     iconName: "user-profile",
            //     items: [{id: "profile", text: "Profile"}, {id: "preferences", text: "Preferences"}, {
            //         id: "security",
            //         text: "Security"
            //     }, {
            //         id: "support-group",
            //         text: "Support",
            //         items: [{
            //             id: "documentation",
            //             text: "Documentation",
            //             href: "#",
            //             external: true,
            //             externalIconAriaLabel: " (opens in new tab)"
            //         }, {id: "support", text: "Support"}, {
            //             id: "feedback",
            //             text: "Feedback",
            //             href: "#",
            //             external: true,
            //             externalIconAriaLabel: " (opens in new tab)"
            //         }]
            //     }, {id: "signout", text: "Sign out"}]
            // }]}
            />
            <Grid
            gridDefinition={[
                { colspan: 6}, 
                { colspan: 6}]}
            >
            <div style={{margin:"0px 0px 0px 20px"}}><Textinput/></div>
            <div style={{margin:"0px 20px 0px 0px"}}>
                <ColumnLayout>
                    <div><Controlpanel/></div>
                    {/* <div><AudioPlayer/></div> */}
                </ColumnLayout>
            </div>
            </Grid>
            {/* <Sidebar/> */}
            {/* <Layout/> */}
        </SpaceBetween>
    </>)
}

export default MainPage

