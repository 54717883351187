// reducers/index.js
import { combineReducers } from 'redux';
import textinputReducer from './textinputReducer';
import generateLinkReducer from './generateLinkReducer';
import invalidInputReducer from './invalidInputReducer';
import generateClickedReducer from './generateClickedReducer';


const rootReducer = combineReducers({
    input: textinputReducer,
    generatedLink: generateLinkReducer,
    invalidInput: invalidInputReducer,
    generateClicked: generateClickedReducer,
});

export default rootReducer;
