import * as React from "react";
import axios from 'axios'
import { connect, useDispatch } from "react-redux";
import { setGeneratedLink, setInvalidInput, setGenerateClicked } from "../reducers/actions";
import Checkbox from "@cloudscape-design/components/checkbox";
import {
    Container,
    Header,
    ColumnLayout,
    Select,
    ExpandableSection,
    FormField,
    Button,
    Alert,
    Input,
    Grid,
  } from "@cloudscape-design/components";


const Controlpanel = ({inputValue, generatedLink, invalidInput, setInvalidInput, setGenerateClicked}) => {
    const [characters, setCharacters] = React.useState([])
    const [emotions, setEmotions] = React.useState([])
    const [rawCharInfo, setRawCharInfo] = React.useState([])
    const [loadCharStatus, setLoadCharStatus] = React.useState('');
    const [streamStatus, setStreamStatus] = React.useState(true);
    const [refreshing, setRefreshing] = React.useState(false);
    const [isGenerating, setIsGenerating] = React.useState(false);
    const [top_k, setTop_k] = React.useState(12);
    const [top_p, setTop_p] = React.useState(0.6);
    const [temperature, setTemperature] = React.useState(0.6);
    const [refreshError, setRefreshError] = React.useState(false);
    const [topkError, setTopkError] = React.useState('');
    const [toppError, setToppError] = React.useState('');
    const [tempError, setTempError] = React.useState('');
    const [speed, setSpeed] = React.useState(1.0);
    const [speedError, setSpeedError] = React.useState('');
    const [inferenceBatch, setInferenceBatch] = React.useState(1);
    const [inferenceBatchError, setInferenceBatchError] = React.useState('');
    const [inferenceBatchHint, setInferenceBatchHint] = React.useState('');
    const [inputCheckPass, setInputCheckPass] = React.useState(false);
    const [needToCheckSettings, setNeedToCheckSettings] = React.useState(false);
    const [selectedOptionChar, setSelectedOptionChar] = React.useState(null);
    const [selectedOptionEmotion, setSelectedOptionEmotion] = React.useState(null);
    const [selectedOptionLanguage, setSelectedOptionLanguage] = React.useState({ label: "Multi-Language Mixed", value: "6" });
    const [generateError, setGenerateError] = React.useState('');
    const [timeoutId, setTimeoutId] = React.useState(null);
    const [isTimerStarted, setIsTimerStarted] = React.useState(false);
    const [reloadTTSError, setReloadTTSError] = React.useState('');
    const [isTTSReloading, setIsTTSReloading] = React.useState(false);

    const audioRef = React.useRef(null);
    const dispatch = useDispatch();

    const languageMap = {
        "Chinese": "all_zh",
        "English": "en",
        "Japanese": "all_ja",
        "Chinese+English": "zh",
        "English+Japanese": "ja",
        "Multi-Language Mixed": "auto",
    }

    function getLanguageCode(language) {
        return languageMap[language.label];
    }

    const handleAudioReload = () => {
        if (audioRef.current) {
            audioRef.current.load();
            audioRef.current.play();
        }
    }

    // 刷新按钮
    const RefreshCharacters = React.useCallback(() => {
          setLoadCharStatus('loading')
          setRefreshing(true);
          axios.get('./character_list', { timeout: 5000 })
                .then(response => {
                    console.log(response);
                    const rawData = response.data;
                    setRawCharInfo(rawData);
                    const processedOptions = processOptions(rawData);
                    setCharacters(processedOptions);
                    setLoadCharStatus('finished');
                    setRefreshing(false);
                    setRefreshError(false);
                })
                .catch(error => {
                    console.error(error);
                    setCharacters([]);
                    setEmotions([]);
                    setSelectedOptionChar(null);
                    setSelectedOptionEmotion(null);
                    setLoadCharStatus('error');
                    setRefreshing(false);
                    setRefreshError(true);
          });
      }, []);


    React.useEffect(() => {
        RefreshCharacters();
        dispatch(setGeneratedLink(''));
        }
        , []);

    
    const processOptions = (data) => {
        let i = 0;
        return Object.keys(data).map(key => ( { label: key, value: i++ } ))
    }

    // 当角色改变时，情感同步更新
    const HandleCharacterChange = (event) => {
        const selectedOption = event.detail.selectedOption;
        setSelectedOptionChar(selectedOption);
        const emotionsFromState = rawCharInfo[selectedOption.label].map((item, index) => ({
            label: item,
            value: index
        }));
        setSelectedOptionEmotion(null); // 重置第二个选择器的选中选项
        setEmotions(emotionsFromState);
    };



    React.useEffect(() => {
        if (!selectedOptionChar || !selectedOptionEmotion || speedError || topkError || toppError || tempError || inferenceBatchError || invalidInput) {
            setInputCheckPass(false);
        } else {
            setInputCheckPass(true);
        }
    }, [selectedOptionChar, selectedOptionEmotion, speedError, topkError, toppError, tempError, inferenceBatchError, invalidInput]);


    const handleError = (e) => {
        const { error } = e.target;
        if (error) {
          switch (error.code) {
            case MediaError.MEDIA_ERR_ABORTED:
              setGenerateError('Audio downloading failed. Try again.');
              break;
            case MediaError.MEDIA_ERR_NETWORK:
              setGenerateError('Network error. Check network connection and try again.');
              break;
            case MediaError.MEDIA_ERR_DECODE:
              setGenerateError('Decoding error. Try again.');
              break;
            case MediaError.MEDIA_ERR_SRC_NOT_SUPPORTED:
              setGenerateError('Unsupported media source. Try another web browser.');
              break;
            default:
              setGenerateError('Unknown Error. Try to refresh and reload service.');
              break;
          }
        }
      };
     
    const handleTimeout = () => {
    setGenerateError('Request timeout. Check input, reload TTS service and try again.');
    setIsGenerating(false);
    };

    const startTimer = (timeoutLength) => {
    if (!isTimerStarted) {
        const timeoutId = setTimeout(handleTimeout, timeoutLength);
        setTimeoutId(timeoutId);
        setIsTimerStarted(true);
    }
    };

    const clearTimer = () => {
    if (timeoutId) {
        clearTimeout(timeoutId);
        setTimeoutId(null);
        setIsTimerStarted(false);
    }
    };

    const reloadTTSService = () => {
        setIsTTSReloading(true);
        axios.get('./reload', { timeout: 3000 })
            .then(response => {
                // console.log(response);
                setTimeout(() => {
                    setReloadTTSError('');
                    setIsTTSReloading(false);
                  }, 10000);
            })
            .catch(error => {
                console.error(error);
                setReloadTTSError('Reload failed. Try again.');
                console.log(reloadTTSError);
                setIsTTSReloading(false);
        });
    }

    // 生成按钮的点击事件，返回生成的链接，并设置isLoaded为true，使得Player组件可以播放该链接。
    // 参数：
    //     selectedOptionChar: 选择的角色
    //     inputValue: 用户输入的文本
    // 返回：
    //     fullLink: 生成的链接
    //     dispatch: 用于触发setGeneratedLink的dispatch，将生成的链接传给reducer。
    //     这个dispatch是用于触发Player组件的setGeneratedLink的。
    const generateClick = () => {
        // checkNoAnyErrors();
        console.log("inputcheckpass "+inputCheckPass);
        console.log("inputvalue "+inputValue);
        console.log("invalidInput "+invalidInput);
        setGenerateClicked(true);
        setGenerateError('');
        clearTimer();
        if ( inputCheckPass === true ) {
            setIsGenerating(true);
            setNeedToCheckSettings(false);
            const baseUrl = './tts';
            const params = new URLSearchParams();
            if (streamStatus) {
                params.append('stream', 'true');
            }
            else {
                params.append('stream', 'false');
            }
            if (selectedOptionChar) {
                params.append('character', selectedOptionChar.label);
            }
            if (inputValue) {
                params.append('text', inputValue);
            }
            if (selectedOptionEmotion) {
                params.append('emotion', selectedOptionEmotion.label);
            }
            if (selectedOptionLanguage) {
                params.append('text_language', getLanguageCode(selectedOptionLanguage));
            }
            if (top_k) {
                params.append('top_k', top_k);
            }
            if (top_p) {
                params.append('top_p', top_p);
            }
            if (temperature) {
                params.append('temperature', temperature);
            }
            if (speed) {
                params.append('speed', speed);
            }
            if (inferenceBatch) {
                params.append('batch_size', inferenceBatch);
            }
            const fullLink = `${baseUrl}?${params.toString()}`
            console.log(fullLink);
            dispatch(setGeneratedLink(fullLink));
            handleAudioReload();
            if (streamStatus) {
                startTimer(30000);
            }
            else {
                startTimer(60000);
            }
            return fullLink;
        }
        else{
            setNeedToCheckSettings(true);
        }
    };

    const playEnded = () => {
        setIsGenerating(false);
    };

    const handleSpeedChange = (event) => {
        const checkValue = event.detail.value;
        if (checkValue <= 0){
            setSpeedError('Value must be greater than 0');
        }
        else{
            setSpeedError('');
            setSpeed(checkValue);
        }
    };

    const handleInferenceBatchChange = (event) => {
        const checkValue = event.detail.value;
        if (checkValue === '' || parseInt(checkValue) <= 0 || !Number.isInteger(parseFloat(checkValue))){
            setInferenceBatchError('Value must be a positive integer.');
        }
        else if (checkValue > 4) {
            setInferenceBatchHint('Inference batch depends on the GPU performance. Recommend value is 1~4.');
            setInferenceBatch(checkValue);
        }
        else{
            setInferenceBatchError('');
            setInferenceBatchHint('');
            setInferenceBatch(checkValue);
        }
    };

    const handleTopkChange = (event) => {
        const checkValue = event.detail.value;
        if (checkValue === '' || parseInt(checkValue) <= 0 || !Number.isInteger(parseFloat(checkValue))){
            setTopkError('Value must be a positive integer.');
        }
        else{
            setTopkError('');
            setTop_k(checkValue);
        }
    };

    const handleToppChange = (event) => {
        const checkValue = event.detail.value;
        if (checkValue < 0 || checkValue > 1){
            setToppError('Value must be between 0 and 1');
        }
        else{
            setToppError('');
            setTop_p(checkValue);
        }
    };

    const handleTempChange = (event) => {
        const checkValue = event.detail.value;
        if (checkValue <= 0){
            setTempError('Value must be greater than 0');
        }
        else{
            setTempError('');
            setTemperature(checkValue);
        }
    };

  return (
    <Container header={<Header>Control Panel</Header>}>
                <ColumnLayout>
                    <div>
                    <FormField
                    description="Choose a character for generating voice. Refresh to get new uploaded models."
                    label="Character"
                    empty="No availible character, try refresh to get new characters"
                    secondaryControl={<Button iconName="refresh" loading={refreshing} onClick={RefreshCharacters}/>}
                    errorText={refreshError ? ("Refresh error, try again."):("")}
                    >
                        <Select
                            selectedOption={selectedOptionChar}
                            onChange={HandleCharacterChange}
                            options={characters}
                            placeholder="Choose a character"
                            loadingText="Loading characters..."
                            statusType={loadCharStatus}
                            errorText="Loading failed. Try again."
                        />
                    </FormField>
                    </div>
                    <div>
                    <FormField
                    label="Emotion"
                    >
                        <Select
                            selectedOption={selectedOptionEmotion}
                            onChange={({ detail }) =>
                                setSelectedOptionEmotion(detail.selectedOption)
                            }
                            options={emotions}
                            empty="Choose a character to get emotions"
                            placeholder="Choose an emotion"
                        />
                    </FormField>
                    </div>
                    <div>
                        <ExpandableSection
                        headerText={
                            <React.Fragment>Advanced Settings</React.Fragment>
                        }
                        >
                            <div style={{margin:"10px 0px"}}>
                            <Alert statusIconAriaLabel="Warning" type="warning">
                            Do not change these settings unless you know what you are doing.
                            </Alert>
                            </div>
                            <Grid
                            gridDefinition={[{ colspan: 4 }, { colspan: 4 }, { colspan: 4 }]}
                            >
                            <div style={{margin:"10px 0px"}}>
                            <FormField
                            label="Language"
                            >
                                <Select
                                    selectedOption={selectedOptionLanguage}
                                    onChange={({ detail }) =>
                                        setSelectedOptionLanguage(detail.selectedOption)
                                    }
                                    options={[
                                        { label: "Chinese", value: "1" },
                                        { label: "English", value: "2" },
                                        { label: "Japanese", value: "3" },
                                        { label: "Chinese+English", value: "4" },
                                        { label: "English+Japanese", value: "5" },
                                        { label: "Multi-Language Mixed", value: "6" }
                                    ]}
                                    placeholder="Choose language of the text"
                                />
                            </FormField>
                            </div>
                            <div style={{margin:"10px 0px"}}> 
                            <FormField
                            label="Speed"
                            errorText={speedError}
                            >
                                <Input
                                onChange={handleSpeedChange}
                                value={speed}
                                step={0.1}
                                type="number"
                                />
                            </FormField>
                            </div>
                            <div style={{margin:"10px 0px"}}> 
                            <FormField
                            label="Inference batch"
                            errorText={inferenceBatchError}
                            constraintText={inferenceBatchHint}
                            >
                                <Input
                                onChange={handleInferenceBatchChange}
                                value={inferenceBatch}
                                step={1}
                                type="number"
                                />
                            </FormField>
                            </div>
                            </Grid>
                            <Grid
                            gridDefinition={[{ colspan: 4 }, { colspan: 4 }, { colspan: 4 }]}
                            >
                            <div style={{margin:"10px 0px"}}> 
                            <FormField
                            label="Top K"
                            errorText={topkError}
                            >
                                <Input
                                onChange={handleTopkChange}
                                value={top_k}
                                step={1}
                                type="number"
                                />
                            </FormField>
                            </div>
                            <div style={{margin:"10px 0px"}}> 
                            <FormField
                            label="Top P"
                            errorText={toppError}
                            >
                                <Input
                                onChange={handleToppChange}
                                value={top_p}
                                step={0.05}
                                type="number"
                                />
                            </FormField>
                            </div>
                            <div style={{margin:"10px 0px"}}> 
                            <FormField
                            label="Temperature"
                            errorText={tempError}
                            >
                                <Input
                                onChange={handleTempChange}
                                value={temperature}
                                step={0.05}
                                type="number"
                                />
                            </FormField>
                            </div>
                            </Grid>
                            <div style={{margin:"10px 0px"}}>
                            <Checkbox
                            onChange={({ detail }) =>
                                setStreamStatus(detail.checked)
                            }
                            checked={streamStatus}
                            >
                            Streaming
                            </Checkbox>
                            </div>
                            <div style={{margin:"10px 0px"}}>
                            <Button
                                onClick={reloadTTSService}
                                loading={isTTSReloading}
                                loadingText="Reloading"
                            >Reload TTS Service</Button>
                            </div>
                        </ExpandableSection>
                    </div>
                    <div>
                    <Button variant="primary" 
                        onClick={generateClick}
                        loading={isGenerating}
                        loadingText="Generating"
                    >Generate</Button>
                    </div>
                    <div>
                    {needToCheckSettings && (<Alert
                    statusIconAriaLabel="Error"
                    type="error"
                    header="Input check failed"
                    >
                    Please check all input and parameters.
                    </Alert>)}
                    </div>
                    <div>
                    {generateError && (<Alert
                    statusIconAriaLabel="Error"
                    type="error"
                    header="Generate failed"
                    onDismiss={() => setGenerateError('')}
                    >
                    {generateError}
                    </Alert>)}
                    </div>
                    <div>
                        <audio ref={audioRef} autoPlay controls onCanPlayThrough={playEnded} onError={handleError} onLoadedData={clearTimer}>
                            <source src={generatedLink}/>
                        </audio>
                    </div>
                    {/* <button onClick={() => console.log(streamStatus)}>DEBUG</button> */}
                </ColumnLayout>
                {/* <p>input: {inputValue}</p> */}
    </Container>
  );
}

const mapStateToProps = (state) => ({
    inputValue: state.input.inputValue,
    generatedLink: state.generatedLink.generatedLink,
    invalidInput: state.invalidInput.invalidInput,
  });

const mapDispatchToProps = (dispatch) => ({
    setInvalidInput: (value) => dispatch(setInvalidInput(value)),
    setGenerateClicked: (value) => dispatch(setGenerateClicked(value)),
  });

export default connect(mapStateToProps, mapDispatchToProps)(Controlpanel);

