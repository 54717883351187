import { SET_INVALID_INPUT } from './actions';

const initialState = {
    invalidInput: false,
  };
  
  const invalidInputReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_INVALID_INPUT:
        return {
          ...state,
          invalidInput: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default invalidInputReducer;