import * as React from "react";
import Container from "@cloudscape-design/components/container";
import Header from "@cloudscape-design/components/header";
import Textarea from "@cloudscape-design/components/textarea";
import { connect } from "react-redux";
import { FormField } from "@cloudscape-design/components";
import { setInvalidInput } from "../reducers/actions";


const InputComponent = ({ inputValue, updateInputValue, invalidInput, setInvalidInput, generateClicked }) => {
    const [countExceeded, setCountExceeded] = React.useState(false);

    const handleInputChange = (event) => {
        const newValue = event.detail.value;
        updateInputValue(newValue);
        // setInvalidInput(newValue.trim() === '');
    };

    React.useEffect(() => {
        setInvalidInput(inputValue.trim() === '');
        setCountExceeded(inputValue.length > 800);
    }, [inputValue]);

    return (
        <Container
        fitHeight
        className="textinput"
        id="1"
        header={<Header>TTS Text</Header>}
        >
        <FormField
        stretch='true'
        constraintText={
            countExceeded &&
            <>
            To avoid unexpected error, TTS text should be no longer than 800 characters. Current characters: {inputValue.length}.
            </>
          }
        errorText={
            generateClicked && invalidInput &&
            "Please fill in TTS text before generate audio."
          }>
            <Textarea
            onChange={handleInputChange}
            value={inputValue}
            placeholder="Input your TTS text here."
            rows={30}
            />
        </FormField>
        </Container>
    );
};

const mapStateToProps = (state) => ({
    inputValue: state.input.inputValue,
    invalidInput: state.invalidInput.invalidInput,
    generateClicked: state.generateClicked.generateClicked
    });
    
const mapDispatchToProps = (dispatch) => ({
    updateInputValue: (value) => dispatch({ type: 'UPDATE_INPUT_VALUE', payload: value }),
    setInvalidInput: (value) => dispatch(setInvalidInput(value)),
    
    });
    
export default connect(mapStateToProps, mapDispatchToProps)(InputComponent);
